<template>
  <div class="catalog">
    <!-- page title -->
    <page-title :pageTitle="titolo"></page-title>

    <!-- ente header -->
    <div class="ente-header">
      <v-container>
        <v-row>
          <v-col class="col-12 col-md-4 d-flex align-center">
            <div class="container-img ente-img">
            <img :src="baseUrl + imgUrl" alt="" class="img-generic-100-enti" />
            </div>
          </v-col>
          <v-col
            class="col-12 col-md-8 pt-8"
            :class="{ 'text-center': $vuetify.breakpoint.xsOnly }"
          >
            <p v-html="descrizione"></p>
          </v-col>
        </v-row>
      </v-container>
    </div>

    <!-- search bar -->
    <div class="row justify-center row-enti-conth">
      <div class="col-6 search-field">
        <open-data-search-field></open-data-search-field>
      </div>
    </div>

    <!-- chosen filter section -->
    <v-container class="py-0">
      <dataset-chosen-filter></dataset-chosen-filter>
    </v-container>

    <!-- results section -->
    <v-container>
      <!-- filters -->
      <v-row class="px-md-4">
        <v-col class="col-sm-3 pl-sm-0" v-if="$vuetify.breakpoint.mdAndUp">
          <dataset-filter-section
            filterName="temi"
            filterType="topics"
            :items="themeList"
          ></dataset-filter-section>
          <dataset-filter-section
            filterName="tag"
            filterType="tags"
            :items="tagList"
          ></dataset-filter-section>
          <dataset-filter-section
            filterName="titolare"
            filterType="owners"
            :items="ownersList"
          ></dataset-filter-section>
        </v-col>

        <!-- mobile filters -->
        <v-col class="col-12 px-16" v-else>
          <v-list-group class="mobile-dropdown" v-model="filterOpen">
            <template v-slot:activator class="filter-button">
              <v-list-item-title>
                FILTRI
                <v-icon>filter_list</v-icon>
              </v-list-item-title>
            </template>
            <v-list-item>
              <dataset-filter-section
                filterName="temi"
                filterType="topics"
                :items="themeList"
              ></dataset-filter-section>
            </v-list-item>
            <v-list-item>
              <dataset-filter-section
                filterName="tag"
                filterType="tags"
                :items="tagList"
              ></dataset-filter-section>
            </v-list-item>
            <v-list-item>
              <dataset-filter-section
                filterName="titolare"
                filterType="owners"
                :items="ownersList"
              ></dataset-filter-section>
            </v-list-item>
          </v-list-group>
        </v-col>

        <v-col class="col-12 col-sm-9 px-8" id="dataset-results">
          <!-- dataset result header -->
          <dataset-result-header
            :datasetCount="datasetCount"
            :key="datasetCount"
          ></dataset-result-header>

          <!-- dataset result -->
          <div class="dataset-result">
            <v-row
              v-for="dataset in datasetList"
              :key="dataset.id"
              class="py-4 dataset-body"
            >
              <dataset-result-card :dataset="dataset"></dataset-result-card>
            </v-row>
          </div>

          <!-- dataset pagination -->
          <div
            class="dataset-pagintion"
            v-if="datasetCount > 0"
            @click="scrollToTop"
          >
            <v-pagination
              color="#2AB5B9"
              circle
              next-icon="keyboard_arrow_right"
              prev-icon="keyboard_arrow_left"
              :total-visible="5"
              :length="totalPages"
              v-model="page"
              @input="onChange"
            ></v-pagination>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import PageTitle from "../components/PageTitle";
import { getEntiDetail } from "../services/api";
import DatasetFilterSection from "../components/DatasetFilterSection";
import OpenDataSearchField from "../components/OpenDataSearchField";
import DatasetResultCard from "../components/DatasetResultCard";
import DatasetResultHeader from "../components/DatasetResultHeader";
import DatasetChosenFilter from "../components/DatasetChosenFilter";
export default {
  name: "EntiDetail",
  components: {
    PageTitle,
    OpenDataSearchField,
    DatasetFilterSection,
    DatasetResultCard,
    DatasetResultHeader,
    DatasetChosenFilter,
  },
  data() {
    return {
      ente: {},
      baseUrl: process.env.VUE_APP_API_BASE_URL,
      page: 1,
      filterOpen: false,
    };
  },
  async created() {
    this.$emit("setLoading", true);

    let entiPromise = getEntiDetail(this.id);

    try {
      let { data } = await entiPromise;
      this.ente = data[0];
    } catch (err) {
      console.log(err);
    }

    let payload = this.searchParams;
    payload.owners = [];
    payload.owners.push(this.filtroDataset);

    this.$store.dispatch("setSearchParams", {
      searchParams: payload,
    });

    this.$emit("setLoading", false);
  },
  computed: {
    id() {
      return this.$route.params.id ?? 0;
    },
    themeList() {
      return this.$store.getters["getDatasetTopicsList"];
    },
    tagList() {
      return this.$store.getters["getDatasetTagsList"];
    },
    ownersList() {
      return this.$store.getters["getDatasetOwnersList"];
    },
    datasetResult() {
      return this.$store.getters["getDatasetList"];
    },
    datasetList() {
      return this.datasetResult.datasets ?? [];
    },
    datasetCount() {
      return this.datasetResult?.totalResults ?? 0;
    },
    filteredEnti() {
      let filtered = this.$store.getters["getEntiList"].filter((r) => {
        return r.id.toString() === this.$route.params.id.toString();
      });
      return filtered;
    },
    titolo() {
      return this.ente?.titolo ?? "";
    },
    descrizione() {
      return this.ente?.descrizione ?? "";
    },
    imgUrl() {
      return this.ente?.url_relativa_img ?? "";
    },
    filtroDataset() {
      return this.ente?.field_filtro_dataset ?? "";
    },
    searchParams() {
      return this.$store.getters["getSearchParams"];
    },
    totalPages() {
      if (this.datasetCount) {
        return (
          Math.floor(this.datasetCount / 10) +
          (this.datasetCount % 10 == 0 ? 0 : 1)
        );
      } else {
        return 0;
      }
    },
    pageIndex() {
      return this.searchParams?.pageIndex + 1 ?? 1;
    },
  },
  methods: {
    onChange() {
      this.$store.dispatch("setPageIndex", {
        pageIndex: this.page - 1,
      });
    },
    scrollToTop() {
      var elmnt = document.getElementById("dataset-results");
      elmnt.scrollIntoView();
    },
  },
  updated() {
    this.page = this.pageIndex;
  },
};
</script>

<style lang="scss">
@import "@/styles/variables.scss";
.row-enti-conth{

  margin-top:20px!important;
}
.ente-header {
  box-shadow: 1px 2px 8px #eaeaea;
  p {
    letter-spacing: 0px;
    color: $text-dark;
    font-size: 23px;
    line-height: 40px;
  }
}
button {
  outline: none;
}
.search-field {
  max-width: 900px;
}
.mobile-dropdown {
  text-align: center;
  border: 1px solid #eaeaea;
  border-radius: 5px;
}
.container-img.ente-img{
  
  height: 170px;
  img.img-generic-100-enti{max-height: 180px;max-width:100%}
}
</style>
